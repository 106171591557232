import moment from "moment";
import { useRouter } from "next/router";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";

import Avatar from "../Avatar";
import Loader from "../Loader";

import * as styles from "./NotificationList.css";

import Icon from "@web/components/Icon/Icon";
import { useNotificationContext } from "@web/context/NotificationContext";
import { neutral } from "@web/styles/palette.css";
import { pointer } from "@web/styles/utils.css";
import { TNotification, TNotificationContent } from "@web/types/notification";
import cx from "@web/utils/cx";

const notificationContent = (
  notification: TNotification,
): { text: React.ReactNode; path: string; page?: string; senderName: string } => {
  const content = JSON.parse(notification.content) as TNotificationContent;
  const projectName = `${content.projectName}`;
  const documentText = `${content.documentNumber} ${content.documentName}`;
  const issueTitle = content.issueTitle;
  const senderName = `${content.senderName}`;
  const modifyContent = content.modificationTypes?.map((reason) => reason.toLocaleLowerCase()).join(", ");
  return {
    ASSIGNED_TO_PROJECT: {
      text: (
        <>
          <b>{senderName}</b> added you to <b>{projectName}</b>
        </>
      ),
      path: `/projects/${content.projectId}`,
      senderName,
    },
    SENIOR_REVIEW_CHECKLIST: {
      text: (
        <>
          <b>{senderName}</b> added you as a reviewer to a checklist for <b>{documentText}</b>
        </>
      ),
      path: `/projects/${content.projectId}/documents`,
      page: "Checklists for Senior Review",
      senderName,
    },
    ASSIGNED_TO_DOCUMENT: {
      text: (
        <>
          <b>{senderName}</b> added you as a reviewer to <b>{documentText}</b>
        </>
      ),
      path: `/projects/${content.projectId}/documents`,
      page: "My documents",
      senderName,
    },
    PC_APPROVAL_ISSUE: {
      text: (
        <>
          <b>{senderName}</b> has raised an Issue: <b>{issueTitle}</b>
        </>
      ),
      path: `/projects/issues/${content.projectId}`,
      senderName,
    },
    REVIEW_DRAFT_ISSUE: {
      text: (
        <>
          <b>{senderName}</b> added you as a reviewer to a draft Issue: <b>{issueTitle}</b>
        </>
      ),
      path: `/projects/issues/${content.projectId}`,
      page: "Draft Issues",
      senderName,
    },
    MODIFIED_REVIEWER_DOCUMENT: {
      text: (
        <>
          <b>{senderName}</b> advises the <b>{modifyContent}</b> of <b>{documentText}</b> has been modified
        </>
      ),
      path: `/projects/${content.projectId}/documents`,
      page: "My documents",
      senderName,
    },
    COMMENT_ON_DOCUMENT: {
      text: (
        <>
          <b>{senderName}</b> tagged you in a comment in <b>{documentText}</b>{" "}
        </>
      ),
      path: `/projects/${content.projectId}/documents?comments=${content.documentId}&commentRef=${content.commentId}`,
      page: "My documents",
      senderName,
    },
    COMMENT_ON_DOCUMENT_FOR_REVIEWER: {
      text: (
        <>
          <b>{senderName}</b> commented on document <b>{documentText}</b>{" "}
        </>
      ),
      path: `/projects/${content.projectId}/documents?comments=${content.documentId}&commentRef=${content.commentId}`,
      page: "My documents",
      senderName,
    },
    COMMENT_ON_ISSUE: {
      text: (
        <>
          <b>{senderName}</b> tagged you in a comment in <b>{issueTitle}</b>
        </>
      ),
      path: `/projects/issues/${content.projectId}?comments=${content.issueId}&commentRef=${content.commentId}`,
      page: "My documents",
      senderName,
    },
    COMMENT_ON_ISSUE_FOR_ASSIGNEE: {
      text: (
        <>
          <b>{senderName}</b> comented on issue <b>{issueTitle}</b>
        </>
      ),
      path: `/projects/issues/${content.projectId}?comments=${content.issueId}&commentRef=${content.commentId}`,
      page: "My documents",
      senderName,
    },
  }[notification.actionType];
};

export default function Notification({ toggleList }: { toggleList: () => void }) {
  const router = useRouter();
  const {
    notifications,
    unreadCount,
    deleteNotification,
    getNotofications,
    markAsRead,
    clearNotifications,
    hasMoreNotifications,
    loading,
  } = useNotificationContext();

  useEffect(() => {
    getNotofications();
    return () => {
      clearNotifications();
    };
  }, []);

  const handleNotificationClick = async (notification: TNotification, path: string, page?: string) => {
    !notification.isRead && markAsRead(notification);
    const routeObj = page ? { pathname: path, query: { tab: page } } : path;
    router.push(routeObj, path);
    toggleList();
  };

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.header}>Notifications</div>
      <div className={styles.count}>{unreadCount} unread</div>
      <div className={styles.divider}></div>
      <div className={styles.notificationList} id="notifications-list">
        <InfiniteScroll
          scrollableTarget="notifications-list"
          dataLength={notifications.length}
          next={getNotofications}
          hasMore={hasMoreNotifications}
          loader={<Loader />}
        >
          <>
            {notifications.map((notification: TNotification) => {
              const content = notificationContent(notification);
              const currentDate = moment().format("MM DD YYYY");
              const date = moment(notification.createdAt).format("MM DD YYYY");
              return (
                <div
                  className={cx(styles, { notification: true, unreadNotification: !notification.isRead })}
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification, content.path, content.page)}
                >
                  <div className={styles.userContainer}>
                    <div className={styles.avatarContainer}>
                      {!notification.isRead && <div className={styles.redMark}>.</div>}
                      <Avatar name={content.senderName} size={28} font={15} backgroundColor={neutral.grey2} />
                    </div>
                    <div className={styles.notificationContent}>{content.text}</div>
                  </div>
                  <div className={styles.dateContainer}>
                    <div>
                      {date == currentDate ? "Today" : <Moment format="DD MMM yyyy">{notification.createdAt}</Moment>}
                    </div>
                    <div className={styles.time}>
                      <Moment format="hh:mm A">{notification.createdAt}</Moment>
                    </div>
                  </div>
                  <Icon
                    className={pointer}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteNotification(notification);
                    }}
                    name="x-mark"
                    size={24}
                  />
                </div>
              );
            })}
          </>
        </InfiniteScroll>
      </div>
      {notifications.length === 0 && !loading && <div className={styles.noNotification}>No notification</div>}
      {loading && <Loader />}
    </div>
  );
}
