import { TDBMetaBase, TPaginatedList } from "./api";
import { TIssue } from "./issue";
import { TChecklist } from "./template";
import { TReviewer } from "./user";

export const enum TDocStatus {
  UNASSIGNED = "UNASSIGNED",
  AWAITING_REVIEW = "AWAITING_REVIEW",
  COMPLETED_REVIEW = "COMPLETED_REVIEW",
  ASSIGNED = "ASSIGNED",
  SENIOR_REVIEW = "SENIOR_REVIEW",
  IN_PROGRESS = "IN_PROGRESS",
  EXCLUDED = "EXCLUDED",
}

export const enum TChangeTrackerStatus {
  NEW = "NEW",
  MODIFIED = "MODIFIED",
  POSSIBLE_DUPLICATE = "POSSIBLE_DUPLICATE",
  DELETED = "DELETED",
  MODIFIED_NOTIFIED = "MODIFIED_NOTIFIED",
}

export enum TChangeTrackerLabel {
  NEW = "New Uploads",
  MODIFIED = "Modified Documents",
  POSSIBLE_DUPLICATE = "Possible Duplicates",
  DELETED = "Deleted Files",
}

export const enum TDocType {
  Folder = "folder",
  Document = "document",
}

export const enum TIndexType {
  ANSARADA_INDEX = "ansarada_index",
  DATASITE_INDEX = "datasite_index",
  NON_VDR_INDEX = "non_vdr_index",
  HOSTED_INDEX = "hosted_index",
}

export type TDocumentBase = {
  date: string;
  docId: string;
  deletedAt: string | null;
  docStatus: TDocStatus;
  docSummary: string | null;
  extension: string;
  id: string;
  link: string;
  name: string;
  number: string;
  parentDocId: string | null;
  projectId: string;
  reviewers: TReviewer[];
  size: string;
  type: TDocType;
  totalNestedDocuments?: number;
  syncStatuses: TChangeTrackerStatus[];
  syncMetaData: string[];
  issuesCount?: number;
  checklists?: TChecklist[];
  issues?: TIssue[];
  comments?: Comment[];
  uploadType: TIndexType;
  subRows?: TDocument[] | null;
  nestedDocuments?: TDocument[] | null;
  uniqueReviewers?: TReviewer[] | null;
};

export type TDocument = TDocumentBase & TDBMetaBase;
export type TDocumentWithSubRows = TDocumentBase & TDBMetaBase & { subRows: TDocument[] | null };

export type TDocumentRows = {
  id: string;
  subRows: TDocument[];
};

export type TDocumentsSummaryStat = {
  count: number;
  status: Exclude<TDocStatus, TDocStatus.EXCLUDED>;
};

export type TDocumentsSummaryResponse = {
  stats: TDocumentsSummaryStat[];
  total: number;
};

export type TDocumentList = Readonly<TDocument[]>;

export type TChangeTrackerStat = {
  count: number;
  status:
    | TChangeTrackerStatus.DELETED
    | TChangeTrackerStatus.MODIFIED
    | TChangeTrackerStatus.NEW
    | TChangeTrackerStatus.POSSIBLE_DUPLICATE;
};

export type TChangeTrackerResponse = {
  versionFrom: string;
  versionTo: string;
  stats: TChangeTrackerStat[];
};

export type TDocumentsPaginatedList = Readonly<TPaginatedList<TDocument>>;
export const enum TDocumentTab {
  ALL_DOCUMENTS = "All Documents",
  MY_DOCUMENTS = "My Documents",
  CHECKLISTS_FOR_SENIOR_REVIEW = "Checklists for Senior Review",
}
export type TDocumentSubTab = "Data Room Documents" | "Other Documents";

export type TDocumentsCountSummary = {
  initialDocs: number;
  newDocs: number;
};

export const enum DocumentHostType {
  HOSTED = "hosted",
  NON_HOSTED = "nonHosted",
}

export enum DocumentFields {
  NUMBER = "NUMBER",
  NAME = "NAME",
  PARENT_NAME = "PARENT NAME",
  SIZE = "SIZE",
  EXTENSION = "EXTENSION",
  DATE = "DATE",
  PARENT_DOC_ID = "PARENT DOC ID",
  CONTENT = "CONTENT",
}
