import "components/App/App.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/App/App.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUYY/iIBCGv++vILlcsuYOw1rrqvdraKHt7CE0lK66l/vvN0BRqu6HzanRAOPLM+/MdGmhPG93jPx5IkTA0Ct+3pNGydMv3PC/VICVtQOj96Q2ajxof1Lx+ndrzajFnnxrwstvY4CxuLMSRbku/E7PhQDd7gnzqwO3Lehp0UloO7cnL4x99+sjCNel5d+nZUR7CWiz+67qSWLL+gB8lwFX0GoKTh4GxJfaSZsSQwp889GZ7LZVuC1h+kOy/pJ2pgSHNqglyGLthS4BRTicko4Y/uYTnfmQYKiSjfdqO9NYB41QJnTniOd5Fa51cfLkaOCduWC0o8eJrjJKZMrl/xpBSMv7BHxhOnYYll2zCdf0ZoDYYVYq7uA9D3nNfVpNcsnTtK6MFdJSywWMCFFG6+5I38bBQXOmNWaOnDntZ53iPRrgQ2Iqq3iXAi3pLcC95zN3N4zdjQ1jVV3V2ZBQZ7xjk+BoB6/YG7jprO2NZ7wacC69rVhor1BzVT/79iE/COIt8uF6LaP8ZS6xKeM42kh6/TMNyS3m3PQ+0eh9ACdIAgIPi6Zsynh4okPHRWjODUaErwIpiG0r/sx+kumzZLvF42fIIyfzuqxjSh8UtPBzvXrQEVOMeZe2UR6mAyGkntmqIDqbrNlOuF9p+s/Ltssmitr06JuNM38UMn9qVHlIqDaNAf8AO/IYucoFAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var header = 'ri5y891';
export var headerBody = 'ri5y894';
export var headerIcons = 'ri5y895';
export var headerLogo = 'ri5y892';
export var orgImage = 'ri5y893';
export var profileMenu = 'ri5y898';
export var profileMenuIcon = 'ri5y899';
export var profileMenuItem = 'ri5y897';
export var profileMenuWrapper = 'ri5y896';
export var root = 'ri5y890';
export var userIcon = 'ri5y89b';
export var usersGroupIcon = 'ri5y89a';