import React from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
/* eslint-disable import/named */
import Select, {
  components,
  CSSObjectWithLabel,
  GroupBase,
  MultiValueGenericProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from "react-select";
import AsyncSelect from "react-select/async";
import { AsyncPaginate } from "react-select-async-paginate";

import Icon, { IconName } from "../Icon/Icon";
import InlineCheckbox from "../InlineCheckbox";
import UserCheckbox from "../UserCheckbox/UserCheckbox";

import * as styles from "./MultiSelect.css";

import { color, neutral } from "@web/styles/palette.css";
import { TDocument } from "@web/types/document";
import { TUser, TUserBase } from "@web/types/user";
import { getUserName } from "@web/utils/user";

type TSearchCandidate = {
  label: string;
  value: string;
  data: {
    department?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    jobTitle?: string;
  };
};

type TMultiSelectProps = {
  name: string;
  label?: string;
  labelIcon?: IconName;
  loadPaginateOptions?: (input: string, undefines: any, { page }: any) => Promise<any>;
  loadOptions?: (input: string) => Promise<any>;
  placeholder?: string;
  async?: boolean;
  withPaginate?: boolean;
  options?: { label: string; value: string }[];
  disabled?: boolean;
  list?: "users" | "documents";
  isMulti?: boolean;
  variant?: "grey";
};

const MultiValueLabel = (props: MultiValueGenericProps<TDocument>) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.number} {props.data.name}
    </components.MultiValueLabel>
  );
};

const Option = (props: OptionProps) => {
  return (
    <div className={styles.option}>
      <components.Option {...props}>
        <div className={styles.optionElement}>
          {props.label}
          <InlineCheckbox checked={props.isSelected} />
        </div>
      </components.Option>
    </div>
  );
};

const UserValue = (props: SingleValueProps<unknown>) => {
  const user = props.data as TUser;
  return (
    <components.SingleValue {...props}>
      {user.id ? (
        <UserCheckbox
          key={user.email}
          name={getUserName(user)}
          email={user.email}
          jobTitle={user.jobTitle}
          department={user.department}
          checkbox={false}
          selected={true}
        />
      ) : (
        "Select reviewer"
      )}
    </components.SingleValue>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<unknown>) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

const UserOption = (props: OptionProps) => {
  const user = props.data as unknown as TUserBase;
  const email = user.email;
  const name = getUserName(user);
  const jobTitle = user.jobTitle;
  const department = user.department;
  return (
    <div className={styles.option}>
      <components.Option {...props}>
        <UserCheckbox
          key={email}
          name={name}
          selected={props.isSelected}
          email={email}
          jobTitle={jobTitle}
          department={department}
          checkbox={false}
        />
      </components.Option>
    </div>
  );
};

function MultiSelect({
  name,
  label,
  labelIcon,
  loadOptions,
  loadPaginateOptions,
  placeholder,
  async,
  options,
  disabled,
  isMulti = true,
  withPaginate = false,
  list = "documents",
  variant,
}: TMultiSelectProps) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const error = errors[name] as FieldError | undefined;
  const backgroundColor = disabled || variant === "grey" ? neutral.grey9 : neutral.white;

  const colourStyles: StylesConfig<unknown, true, GroupBase<unknown>> = {
    control: (styles: CSSObjectWithLabel) =>
      ({
        ...styles,
        backgroundColor: backgroundColor,
        borderColor: error ? color.errorBorder : neutral.grey7,
        padding: "6px 2px",
      }) as CSSObjectWithLabel,
    menu: (styles: CSSObjectWithLabel) => ({ ...styles, padding: "8px" }) as CSSObjectWithLabel,
    option: (styles: CSSObjectWithLabel, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected || isFocused ? neutral.grey9 : neutral.white,
        color: neutral.grey2,
      } as CSSObjectWithLabel;
    },
    indicatorSeparator: (defaultStyles: CSSObjectWithLabel) => {
      return {
        ...defaultStyles,
        display: "none",
      } as CSSObjectWithLabel;
    },
    multiValue: (styles: CSSObjectWithLabel) => {
      return {
        ...styles,
        backgroundColor: neutral.grey8,
        padding: "8px",
      } as CSSObjectWithLabel;
    },
    multiValueLabel: (styles: CSSObjectWithLabel) =>
      ({
        ...styles,
        padding: 0,
        position: "relative",
        top: "1px",
        fontSize: "14px",
        marginRight: "8px",
      }) as CSSObjectWithLabel,
    multiValueRemove: (styles: CSSObjectWithLabel) =>
      ({
        ...styles,
        fontSize: "20px",
        ":hover": {
          backgroundColor: neutral.grey8,
        },
      }) as CSSObjectWithLabel,
    placeholder: (styles: CSSObjectWithLabel) => {
      return {
        ...styles,
        color: neutral.grey5,
        fontSize: "14px",
      } as CSSObjectWithLabel;
    },
  };

  const filterOptions = (candidate: TSearchCandidate, input: string): boolean => {
    if (input) {
      const lowerInput = input.toLowerCase();
      const { department = "", firstName = "", lastName = "", email = "", jobTitle = "" } = candidate.data;
      const dataString = [department, firstName, lastName, email, jobTitle].join(" ").toLowerCase();

      return dataString.includes(lowerInput);
    }
    return true;
  };

  return (
    <div className={styles.root}>
      {label && (
        <div className={styles.label}>
          {labelIcon && <Icon name={labelIcon} size={16} />} {label}
        </div>
      )}
      <Controller
        control={control}
        {...register(name)}
        render={({ field }) => {
          const optionDropdown = list === "users" ? UserOption : Option;
          const props = {
            closeMenuOnSelect: !isMulti,
            hideSelectedOptions: false,
            ...field,
            isMulti: isMulti,
            defaultOptions: true,
            styles: colourStyles,
            isClearable: true,
            placeholder,
            isDisabled: disabled,
          };
          if (async && withPaginate && loadPaginateOptions) {
            return (
              <AsyncPaginate
                components={{
                  Option: optionDropdown,
                  MultiValueLabel: MultiValueLabel,
                  SingleValue: list == "users" ? UserValue : SingleValue,
                }}
                loadOptions={loadPaginateOptions}
                {...props}
                menuPlacement="auto"
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
              />
            );
          } else if (async) {
            return (
              <AsyncSelect
                components={{
                  Option: optionDropdown,
                  MultiValueLabel: MultiValueLabel,
                  SingleValue: list == "users" ? UserValue : SingleValue,
                }}
                loadOptions={loadOptions}
                {...props}
                menuPlacement="auto"
              />
            );
          } else {
            return (
              <Select
                components={{ Option: optionDropdown }}
                options={options}
                {...props}
                menuPlacement={"auto"}
                filterOption={filterOptions}
              />
            );
          }
        }}
      />
      {error != null ? <div className={styles.errors}>{error.message}</div> : null}
    </div>
  );
}

export default MultiSelect;
