import { useEffect, useState } from "react";
import * as z from "zod";

import IssueForm from "../IssueForm";

import Form from "@web/components/Form";
import { TDocument } from "@web/types/document";
import { TIssue, TIssueFormValues } from "@web/types/issue";

const issueFormSchema: z.Schema = z
  .object({
    riskStatus: z.string().min(1, { message: "Enter Risk Status" }),
    riskRating: z.string().min(1, { message: "Enter Risk Rating" }),
    issueCategory: z.string().min(1, { message: "Enter category" }),
    issueTitle: z.string().min(1, { message: "Enter title" }),
    issueDescription: z.string().min(1, { message: "Enter description" }),
    recommendationTitle: z.string().optional(),
    recommendation: z.string().optional(),
    relatedDoc: z.array(z.any()).optional(),
    assignSeniorReview: z.boolean().optional(),
    seniorReview: z.any().optional(),
  })
  .refine(
    (data) => {
      if (data.assignSeniorReview && !data.seniorReview) {
        return false;
      }
      return true;
    },
    {
      message: "Select senior review",
      path: ["seniorReview"],
    },
  );

const initialValues = {
  riskStatus: "",
  riskRating: "",
  relatedDocs: [],
  issueCategory: "",
  issueTitle: "",
  issueDescription: "",
  recommendationTitle: "",
  recommendation: "",
  seniorReview: undefined,
  assignSeniorReview: false,
};

const IssueModal = ({ document, issue }: { document?: TDocument; issue?: TIssue }) => {
  const [formValues, setFormValues] = useState<TIssueFormValues>(initialValues);

  useEffect(() => {
    if (issue?.id) {
      setFormValues({
        riskStatus: issue.riskStatus,
        riskRating: issue.riskRating,
        issueCategory: issue.issueCategory,
        issueTitle: issue.issueTitle,
        issueDescription: issue.issueDescription,
        recommendationTitle: issue.recommendationTitle,
        recommendation: issue.recommendation,
        seniorReview: issue.seniorReviewer,
        assignSeniorReview: !!issue.seniorReviewerId,
        relatedDocs: issue.relatedDocuments
          ? issue.relatedDocuments
              .filter((doc) => !!doc.document)
              .map((doc) => ({
                ...doc.document,
                value: doc.document.id,
                label: `${doc.document.number}` + " " + `${doc.document.name}`,
              }))
          : [],
      });
    }
  }, [issue?.id]);

  return (
    <>
      <Form schema={issueFormSchema} values={formValues}>
        <IssueForm issue={issue} document={document} />
      </Form>
    </>
  );
};

export default IssueModal;
