import "components/CommentBox/CommentBox.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/CommentBox/CommentBox.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV226jMBB971dYqlZqpXUEAZJAX3c/xGBD3BqbtU1zWfXf1xcgOKGrXkiEYDw+M+d4ZljhQy5fzxH4ewcApqpj6FSAmpHjkzGcIeWYHAsQ27cDxXpfgGQddW7VekFMJak0FbwAlWB9y+0KYrThkGrSKg8GlUZS26USVS+NFD3HBbiv3TVhqb2k/KUA0dPd293KZxa7zDqhqA9S0yPBdoekzV4bX59LKbQW7fSqRVeA3bRmoREWhwLAeNMdgbslmbkZFyCbEj1EP8HwX0X5o922Jz5ChVj1EEfRDwAd5OMsu/Wybs+90rQ+wUpwTbjBUB2qCCyJPhByq1BlfIi8mBVhtdmjJdHVfhYtcdGMzEIa8SJ3OfFMGKjomRRgnQ5n40z6xIyNC9kiNlkPA69SMCcko5zAkezoO8VMXcyWcjgcv30ceM3csmUhGmTPwae0yO1CZ0t22zy/ohN/nE7qtQjYxC70lORmOckOYUx5cxFvMEBXRfE6NI6VNtq/1AVOF1uF/xGmFBITeYlnKlUJRjG4Jxv7mxHbOmIedCj6sFknz93FM1tyfLdBQ01C0NyBDkC2U+yGFsmG8rCZc0B512vnPtCTCNNe3UCi5aN6p2088yRAKJcRvnBcE2QV9N8aJ1maXBfs5lsFe3VaOAi43CHr73XIJghIXEDxSmTN7LhEvRajau4rMHnWH0htrsUnWDe35TSt7d+vndEctEq1zXZpOgOgQd7j8uLQWRglY1n7NkgX+PmpOoV7nuc7ULpmu/rD3ERFJp78JL9xFMynmP+s3YQgmGrh8Vt0nIhlwxwYDx2aZlGVFIxdIfSUMdianjN9A402L4GSSZLlv367gd5LZU2doL4/3/4BixO/tWUIAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var backdrop = 'dw9rvzj';
export var button = 'dw9rvz4';
export var commentContainer = 'dw9rvz7 dw9rvz6';
export var commentHeader = 'dw9rvzc';
export var commentHeaderContainer = 'dw9rvza';
export var commentSubHeaderContainer = 'dw9rvzb';
export var commentTextContainer = 'dw9rvzf';
export var commentTime = 'dw9rvzd';
export var commentsContainer = 'dw9rvze';
export var container = 'dw9rvz6';
export var documentContainer = 'dw9rvz5';
export var editorContainer = 'dw9rvzg';
export var editorError = 'dw9rvzh';
export var fixedPosition = 'dw9rvz1';
export var footerContainer = 'dw9rvz8 dw9rvz6';
export var header = 'dw9rvz3';
export var headerContainer = 'dw9rvz2';
export var inputErrorMsg = 'dw9rvzi';
export var root = 'dw9rvz0';
export var textField = 'dw9rvz9';