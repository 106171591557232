import React from "react";

import * as styles from "./Modal.css";

import cx from "@web/utils/cx";

export type TModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  align?: "top" | "center";
  className?: string;
};

const Modal = ({ isOpen, children, align = "top", className }: TModalProps): React.ReactElement | null => {
  if (isOpen) {
    return (
      <div className={cx(styles, { modal: true, center: align === "center", top: align === "top" })}>
        <div className={[styles.modalContainer, className].join(" ")}>{children}</div>
      </div>
    );
  }
  return null;
};

export default Modal;
