import "components/Button/Button.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/Button/Button.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VV247bIBB9369AqvroFXYSO/F+TIVh7NASsDDebLbqvxeMLxC7UrrajZ9yOMyZOTPA84/0lRY3jDH6/YQQVULpEn2rh9+LRWolTdLxdyhRum/fHCS4hOQMvDkbC2L8feZdR7DA2GEVob8arXrJbEiMK1pRB1+4TK6cmbMLiX3MmgsR6baEMS6bEmGUjbqT5LSnUpqBzRYvfxJNGO87y/EU2uvOFdQqLg1oBzHetYLcSsTlUEgtYKASwRuZcAMXu53CRP/Zd4bXt4Ta+iwYLjWkLdHRCf15ep59LK0AqQQwb+iYgFQmIUKoK7CVM5C772Xb/tiXQCgd4keBCjgWp1PEytYsYDnFWcTarVkZ2x32u4i1X7P+Y06mjowlTvHnEhdg6mvavqFOCc4Wi4Js8iEbv5nLM2huovViWF/G6N52o4nsWqJtO8O8Jg+jMSW9UUGqGz4fP6a2eSju1CZOqBYP2QNCy4yNUTccPX1mf9OsKopDILgAj/WXfGY2ADnb47D8GXgsm2rjHI0hAhb1rM2raK0Ttj+OXNSVr/N+TgIt9rGJW47Zvydu4+yzL5k48DWojhuupM2jss70Btwu7Ts59sgYdSnRbrr5tx+W2QxrOcpD2+dmZPFcbE/USPKQuQlw97e+ELF66A7+obt7EYcn4S8wx5r1WgcAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var black = '_1vc7y003';
export var blackLink = '_1vc7y00d';
export var caution = '_1vc7y002';
export var deleteBtn = '_1vc7y00a';
export var deleteFillBtn = '_1vc7y00b';
export var icon = '_1vc7y006';
export var link = '_1vc7y007';
export var primaryFade = '_1vc7y00c';
export var primaryLink = '_1vc7y008';
export var rightIcon = '_1vc7y005';
export var root = '_1vc7y000';
export var secondary = '_1vc7y001';
export var success = '_1vc7y009';
export var tag = '_1vc7y00e';
export var white = '_1vc7y004';