import { AxiosInstance } from "axios";

import { TAiAnswer, TAiDocument } from "@web/types/ai";
import { TAuditLogsPaginatedList } from "@web/types/audit-logs";
import { TComment } from "@web/types/comment";
import {
  DocumentHostType,
  TChangeTrackerResponse,
  TChangeTrackerStatus,
  TDocument,
  TDocumentList,
  TDocumentsCountSummary,
  TDocumentsPaginatedList,
  TDocumentsSummaryResponse,
} from "@web/types/document";
import { TFeatureFlagWithStatus, TOrganisationFeatureFlag } from "@web/types/feature-flag";
import { TCreateFolderData } from "@web/types/folder";
import {
  EIssueStatus,
  TCreateIssueCommand,
  TIssue,
  TIssuesCount,
  TIssuesPaginatedList,
  TIssueSummaryResponse,
} from "@web/types/issue";
import { TNotificationsPaginatedList, TUnreadNitificationResponse } from "@web/types/notification";
import { TAiOptionDTO } from "@web/types/options";
import {
  TCreateOrganisationCommand,
  TOrganisation,
  TOrganisationPaginatedList,
  TUpdateOrganisationCommand,
} from "@web/types/organisation";
import { TPlatformAnalytics } from "@web/types/platform-analytics";
import {
  TCreateProjectCommand,
  TGetProjectsCommand,
  TProject,
  TProjectBase,
  TProjectIndexType,
  TProjectsPaginatedList,
  TUpdateProjectCommand,
} from "@web/types/project";
import { TDeltaStatic } from "@web/types/rich-editor";
import { TTeamSummary } from "@web/types/team-summary";
import {
  EChecklistCompletionStatus,
  TChecklist,
  TCreateExportTemplateCommand,
  TCreateTemplateCommand,
  TExportTemplate,
  TProjectTemplate,
  TProjectTemplatePaginatedList,
  TTemplate,
  TTemplatePaginatedList,
} from "@web/types/template";
import {
  EUserRoles,
  TGetUsersCommand,
  TProjectUser,
  TReviewer,
  TReviewerBase,
  TUser,
  TUsersPaginatedList,
} from "@web/types/user";

export type TApi = {
  listOrganisations: (page: number, limit: number) => Promise<TOrganisationPaginatedList>;
  getOrganisationById: (id: string) => Promise<TOrganisation>;
  createOrganisation: (data: TCreateOrganisationCommand) => Promise<TOrganisation>;
  updateOrganisation: (data: TUpdateOrganisationCommand) => Promise<TOrganisation>;
  getAllProjects: (organisationId: string, params?: TGetProjectsCommand) => Promise<TProjectsPaginatedList>;
  getProjectById: (id: string) => Promise<TProject>;
  createProject: (organisationId: string, data: TCreateProjectCommand) => Promise<TProject>;
  updateProject: (data: TUpdateProjectCommand) => Promise<TProject>;
  archiveProject: (id: string, isActive: boolean) => Promise<TProject>;
  getProjectDocumentSummary: (id: string) => Promise<TDocumentsSummaryResponse>;
  uploadIndexDocument: (data: {
    file: File;
    projectId: string;
    indexType?: TProjectIndexType;
  }) => Promise<TDocumentList>;
  uploadIndexZip: (data: { file: File; projectId: string }) => Promise<TDocumentList>;
  getIndexDocument: (
    projectId: string,
    params?: { syncStatus?: TChangeTrackerStatus; type: DocumentHostType },
  ) => Promise<TDocumentList>;
  getExpandedRow: (documentId: string) => Promise<TDocumentList>;
  getAllUsersBy: (organisationId: string, params?: TGetUsersCommand) => Promise<TUsersPaginatedList>;
  getAllUsersToInvite: (
    organisationId: string,
    params: {
      id: string;
      page: number;
      limit: number;
    },
  ) => Promise<TUsersPaginatedList>;
  updateOrgUserRole: (id: string, role: string) => Promise<TUser>;
  addUsersToProject: (projectId: string, data: { ids: string[] }) => Promise<TProjectUser[]>;
  getAllProjectUsers: (projectId: string, params?: TGetUsersCommand) => Promise<TUsersPaginatedList>;
  updateProjectUserRole: (projectId: string, data: { userId: string; role: EUserRoles }) => void;
  updateOrganisationActivity: (id: string, data: { isActive: boolean }) => void;
  getUser: () => Promise<TUser>;
  updateDocumentReviewers: (
    documentId: string,
    data: { assignees: string[]; nonAssignees: string[] },
  ) => Promise<{ assigned: TReviewer[]; unAssigned: TReviewer[] }>;
  updateBulkDocumentReviewers: (documentId: string, data: { ids: string[] }) => Promise<TReviewerBase[]>;
  getRootDocumentReviewers: (documentId: string) => Promise<TReviewer[]>;
  createTemplate: (organisationId: string, data: TCreateTemplateCommand) => void;
  getTemplateById: (templateId: string) => Promise<TTemplate>;
  updateTemplate: (organisationId: string, templateId: string, data: TCreateTemplateCommand) => void;
  getDocument: (documentId: string) => Promise<TDocument>;
  getChangeTrackerDetails: (projectId: string) => Promise<TChangeTrackerResponse>;
  ignoreFingerprintModifiedDocuments: (projectId: string) => Promise<TChangeTrackerResponse>;
  ignoreAllModifiedDocuments: (projectId: string) => Promise<TChangeTrackerResponse>;
  undeleteAllDocuments: (projectId: string) => Promise<TChangeTrackerResponse>;
  getTemplates: (organisationId: string, page: number, limit: number) => Promise<TTemplatePaginatedList>;
  reSyncIndexDocument: (projectId: string, file: File) => void;
  reSyncNonVDRDocument: (projectId: string, file: File) => void;
  deleteDocument: (id: string) => void;
  assignTemplatesToProject: (projectId: string, templates: { id: string; aiEnabled: boolean }[]) => void;
  getDocumentHierarchy: (id: string) => Promise<TDocumentList>;
  getRelatedDocuments: (
    projectId: string,
    params: { search?: string; page: number; limit: number },
  ) => Promise<TDocumentsPaginatedList>;
  getMyDocuments: (projectId: string) => Promise<TDocumentList>;
  getProjectTemplates: (projectId: string, page: number, limit: number) => Promise<TProjectTemplatePaginatedList>;
  getProjectNotAssignedTemplates: (projectId: string, page: number, limit: number) => Promise<TTemplatePaginatedList>;
  saveChecklist: (id: string, data: unknown) => Promise<TChecklist>;
  updateChecklistStatus: (id: string, completionStatus: EChecklistCompletionStatus) => Promise<TChecklist>;
  checklistSeniorReview: (id: string, reviewerId: string) => Promise<TChecklist>;
  getReviewDocuments: (projectId: string) => Promise<TDocumentList>;
  addDocumentChecklist: (documentId: string, templateId: string, aiEnabled: boolean) => Promise<TChecklist>;
  getSelectedChecklists: (documentId: string) => Promise<TChecklist[]>;
  getIssueById: (id: string) => Promise<TIssue>;
  createIssue: (documentId: string, data: TCreateIssueCommand) => Promise<TIssue>;
  updateIssue: (id: string, data: TCreateIssueCommand) => Promise<TIssue>;
  deleteIssue: (id: string) => Promise<TIssue>;
  updateIssueStatus: (id: string, status: EIssueStatus) => Promise<TIssue>;
  getIssuesCategories: (projectId: string, params: { isDraft: boolean }) => Promise<string[]>;
  getIssues: (projectId: string, params: { category: string; isDraft: boolean }) => Promise<TIssue[]>;
  getIssueSummary: (projectId: string) => Promise<TIssueSummaryResponse>;
  notifyModifiedDocument: (documentId: string, body: { ids: string[] }) => void;
  markDocument: (
    documentId: string,
    body: { exclude: boolean },
  ) => Promise<{ documents: TDocument; duplicateDocuments: TDocument[] }>;
  getNotifications: (params: { page: number; limit: number }) => Promise<TNotificationsPaginatedList>;
  getUnreadNotoficationCount: () => Promise<TUnreadNitificationResponse>;
  deleteNotification: (id: string) => Promise<{ id: string }>;
  markAsReadNotification: (id: string) => Promise<{ id: string }>;
  getIssuesCount: (documentId: string) => Promise<TIssuesCount>;
  updateIssueReviewers: (issueId: string, body: { assignees: string[]; nonAssignees: string[] }) => void;
  getMyIssues: (projectId: string, params: { category: string; isDraft: boolean }) => Promise<TIssue[]>;
  getExportIssues: (
    projectId: string,
    params?: { category?: string; rating?: string; page: number; limit: number },
  ) => Promise<TIssuesPaginatedList>;
  getAuditLogs: (projectId: string, page: number, limit: number) => Promise<TAuditLogsPaginatedList>;
  getExpandAll: (documentId: string) => Promise<TDocumentList>;
  completeDocument: (documentId: string) => Promise<TDocument>;
  excludeBulkDocuments: (
    projectId: string,
    data: { documentIds: string[]; exclude: boolean },
  ) => Promise<{ documents: TDocument[]; duplicateDocuments: TDocument[] }>;
  canBulkAssign: (projectId: string, params: { documentIds: string[] }) => Promise<{ assignable: boolean }>;
  getUniqueAssignees: (
    projectId: string,
    data: { documentIds: string[] },
  ) => Promise<{ documents: TDocument[]; assignees: TUser[] }>;
  bulkAssign: (projectId: string, data: { documentIds: string[]; reviewerIds: string[] }) => void;
  getTotalDocumentCount: (projectId: string) => Promise<TDocumentsCountSummary>;
  getProjectChecklists: (projectId: string, completionStatus: EChecklistCompletionStatus) => Promise<TChecklist[]>;
  createProjectIssue: (projectId: string, data: TCreateIssueCommand) => Promise<TIssue>;
  getDocumentComments: (documentId: string) => Promise<TComment[]>;
  postDocumentComment: (documentId: string, comment: TDeltaStatic) => Promise<TComment>;
  getIssueComments: (issueId: string) => Promise<TComment[]>;
  postIssueComment: (issueId: string, comment: TDeltaStatic) => Promise<TComment>;
  getDocumentsToExport: (projectId: string) => Promise<(TDocument & { reviewedByUser: TUser })[]>;
  getTeamSummary: (projectId: string) => Promise<TTeamSummary>;
  markNotDuplicateDocument: (documentId: string) => Promise<{ documents: TDocument; duplicateDocuments: TDocument[] }>;
  getOrganizationFeatureFlags: (organizationId: string) => Promise<TFeatureFlagWithStatus[]>;
  updateOrganizationFeatureFlags: (
    organizationId: string,
    featureFlagId: string,
    isEnabled: boolean,
  ) => Promise<TOrganisationFeatureFlag>;
  removeChecklist: (checklistId: string) => Promise<TChecklist>;
  getExportTemplate: (organizationId: string, type: string) => Promise<TExportTemplate>;
  uploadOtherDocument: (projectId: string, file: File, path: string, parentDocId?: string | null) => Promise<TDocument>;
  createFolder: (projectId: string, data: TCreateFolderData) => Promise<TDocument>;
  deleteExternalDocument: (projectId: string, documentId: string) => Promise<TDocument>;
  getAllExportTemplates: (organizationId: string) => Promise<TExportTemplate[]>;
  uploadExportTemplate: (organizationId: string, data: TCreateExportTemplateCommand) => Promise<TExportTemplate>;
  deleteExportTemplate: (organizationId: string, exportTemplateId: string) => Promise<{ id: string }>;
  getExportTemplateById: (organizationId: string, exportTemplateId: string) => Promise<TExportTemplate>;
  updateExportTemplate: (
    organizationId: string,
    exportTemplateId: string,
    data: TCreateExportTemplateCommand,
  ) => Promise<TExportTemplate>;
  getFolder: (projectId: string, folderId: string) => Promise<TDocumentList>;
  getHostedDocument: (projectId: string, documentId: string) => Promise<{ documentUrl: string }>;
  aiEvaluateChecklist: (projectId: string, checklistId: string) => Promise<TAiDocument>;
  getAiAnswers: (projectId: string, checklistId: string) => Promise<TAiAnswer[]>;
  getAIFields: (organizationId: string) => Promise<TAiOptionDTO[]>;
  markProjectChecklist: (projectId: string, templateId: string, aiEnabled: boolean) => Promise<TProjectTemplate>;
  getPlatformAnalytics: () => Promise<TPlatformAnalytics[]>;
};

export default function api(http: AxiosInstance): TApi {
  return {
    listOrganisations: async (page: number, limit: number): Promise<TOrganisationPaginatedList> => {
      const response = await http.get(`/organizations?page=${page}&limit=${limit}`);
      return response.data;
    },
    getOrganisationById: async (id: string): Promise<TOrganisation> => {
      const response = await http.get(`/organizations/${id}`);
      return response.data;
    },
    createOrganisation: async (data: TCreateOrganisationCommand): Promise<TOrganisation> => {
      const response = await http.post(`/organizations`, data);
      return response.data;
    },
    updateOrganisation: async (data: TUpdateOrganisationCommand): Promise<TOrganisation> => {
      const response = await http.put(`/organizations/${data.id}`, data);
      return response.data;
    },
    getAllProjects: async (organisationId: string, params?: TGetProjectsCommand): Promise<TProjectsPaginatedList> => {
      const response = await http.get(`/organizations/${organisationId}/projects`, { params });
      return response.data;
    },
    getProjectById: async (id: string): Promise<TProjectBase> => {
      const response = await http.get(`/projects/${id}`);
      return response.data;
    },
    createProject: async (organisationId: string, data: TCreateProjectCommand): Promise<TProject> => {
      const response = await http.post(`/organizations/${organisationId}/projects`, data);
      return response.data;
    },
    updateProject: async (data: TUpdateProjectCommand): Promise<TProject> => {
      const response = await http.put(`/projects/${data.id}`, data);
      return response.data;
    },
    archiveProject: async (id: string, isArchived: boolean): Promise<TProject> => {
      const response = await http.patch(`/projects/${id}`, { isArchived });
      return response.data;
    },
    getProjectDocumentSummary: async (id: string): Promise<TDocumentsSummaryResponse> => {
      const response = await http.get(`/projects/${id}/document-summary`);
      return response.data;
    },
    uploadIndexDocument: async ({ file, projectId, indexType }): Promise<TDocumentList> => {
      const formData = new FormData();
      formData.append("file", file);

      let url = `/projects/${projectId}/documents`;

      if (indexType) {
        url += `?indexType=${encodeURIComponent(indexType)}`;
      }

      const response = await http.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return response.data;
    },
    uploadIndexZip: async ({ file, projectId }): Promise<TDocumentList> => {
      const formData = new FormData();
      formData.append("file", file);
      const response = await http.post(`/projects/${projectId}/documents/non-vdr`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    },
    getIndexDocument: async (
      projectId: string,
      params?: { syncStatus?: TChangeTrackerStatus },
    ): Promise<TDocumentList> => {
      const response = await http.get(`/projects/${projectId}/documents`, { params: params });
      return response.data;
    },
    getExpandedRow: async (documentId: string): Promise<TDocumentList> => {
      const response = await http.get(`/documents/${documentId}/children`);
      return response.data;
    },
    getAllUsersBy: async (organisationId: string, params?: TGetUsersCommand): Promise<TUsersPaginatedList> => {
      const response = await http.get(`/organizations/${organisationId}/users`, { params });
      return response.data;
    },
    getAllUsersToInvite: async (
      organisationId: string,
      params: {
        id: string;
        page: number;
        limit: number;
      },
    ): Promise<TUsersPaginatedList> => {
      const response = await http.get(`/organizations/${organisationId}/users/invite`, {
        params,
      });
      return response.data;
    },
    updateOrgUserRole: async (id: string, role: string): Promise<TUser> => {
      const response = await http.patch(`/users/${id}`, { role });
      return response.data;
    },
    getAllProjectUsers: async (projectId: string, params?: TGetUsersCommand): Promise<TUsersPaginatedList> => {
      const response = await http.get(`/projects/${projectId}/users`, { params });
      return response.data;
    },
    addUsersToProject: async (projectId: string, data: { ids: string[] }): Promise<TProjectUser[]> => {
      const response = await http.post(`/projects/${projectId}/users`, data);
      return response.data;
    },
    updateProjectUserRole: async (projectId: string, data: { userId: string; role: EUserRoles }) => {
      await http.put(`/projects/${projectId}/users/change-role`, data);
    },
    updateOrganisationActivity: async (id: string, data: { isActive: boolean }) => {
      await http.patch(`/organizations/${id}`, data);
    },
    getUser: async (): Promise<TUser> => {
      const response = await http.get("/users/me");
      return response.data.user;
    },
    updateDocumentReviewers: async (
      documentId: string,
      data: { assignees: string[]; nonAssignees: string[] },
    ): Promise<{ assigned: TReviewer[]; unAssigned: TReviewer[] }> => {
      const response = await http.put(`/documents/${documentId}/reviewers`, data);
      return response.data;
    },
    updateBulkDocumentReviewers: async (documentId: string, data: { ids: string[] }): Promise<TReviewerBase[]> => {
      const response = await http.post(`/documents/${documentId}/bulk-assign`, data);
      return response.data;
    },
    getRootDocumentReviewers: async (documentId: string): Promise<TReviewer[]> => {
      const response = await http.get(`/documents/root/${documentId}/reviewers`);
      return response.data;
    },
    getTemplateById: async (templateId: string) => {
      const response = await http.get(`/templates/${templateId}`);
      return response.data;
    },
    createTemplate: async (organisationId: string, data: TCreateTemplateCommand) => {
      const response = await http.post(`/organizations/${organisationId}/templates`, data, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    },
    updateTemplate: async (organisationId: string, templateId: string, data: TCreateTemplateCommand) => {
      const response = await http.put(`/organizations/${organisationId}/templates/${templateId}`, data, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    },
    getDocument: async (documentId: string): Promise<TDocument> => {
      const response = await http.get(`/documents/${documentId}`);
      return response.data;
    },
    getChangeTrackerDetails: async (projectId: string): Promise<TChangeTrackerResponse> => {
      const response = await http.get(`/projects/${projectId}/tracker-details`);
      return response.data;
    },
    ignoreFingerprintModifiedDocuments: async (projectId: string): Promise<TChangeTrackerResponse> => {
      const response = await http.put(`/projects/${projectId}/tracker-details`, { ignoreFingerprint: true });
      return response.data;
    },
    ignoreAllModifiedDocuments: async (projectId: string): Promise<TChangeTrackerResponse> => {
      const response = await http.put(`/projects/${projectId}/tracker-details`, { ignoreAllModified: true });
      return response.data;
    },
    undeleteAllDocuments: async (projectId: string): Promise<TChangeTrackerResponse> => {
      const response = await http.put(`/projects/${projectId}/tracker-details`, { undeleteAll: true });
      return response.data;
    },
    getTemplates: async (organisationId: string, page: number, limit: number): Promise<TTemplatePaginatedList> => {
      const response = await http.get(`/organizations/${organisationId}/templates`, { params: { page, limit } });
      return response.data;
    },
    reSyncIndexDocument: async (projectId: string, file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      await http.post(`/projects/${projectId}/documents/re-sync`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    reSyncNonVDRDocument: async (projectId: string, file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      await http.post(`/projects/${projectId}/documents/re-sync/non-vdr`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    deleteDocument: async (id: string) => {
      await http.delete(`/documents/${id}`);
    },
    assignTemplatesToProject: async (
      projectId: string,
      templates: { id: string; aiEnabled: boolean }[],
    ): Promise<TTemplate[]> => {
      const response = await http.post(`/projects/${projectId}/templates`, { templates });
      return response.data;
    },
    getDocumentHierarchy: async (id: string): Promise<TDocumentList> => {
      const response = await http.get(`/documents/${id}/hierarchy`);
      return response.data;
    },
    getRelatedDocuments: async (
      projectId: string,
      params: { search?: string; page: number; limit: number },
    ): Promise<TDocumentsPaginatedList> => {
      const response = await http.get(`/projects/${projectId}/all-documents`, { params });
      return response.data;
    },
    getMyDocuments: async (projectId: string): Promise<TDocumentList> => {
      const response = await http.get(`/projects/${projectId}/my-documents`);
      return response.data;
    },
    getProjectTemplates: async (
      projectId: string,
      page: number,
      limit: number,
    ): Promise<TProjectTemplatePaginatedList> => {
      const response = await http.get(`/projects/${projectId}/templates`, { params: { page, limit } });
      return response.data;
    },
    getProjectNotAssignedTemplates: async (
      projectId: string,
      page: number,
      limit: number,
    ): Promise<TTemplatePaginatedList> => {
      const response = await http.get(`/projects/${projectId}/templates`, {
        params: { page, limit, nonProject: true },
      });
      return response.data;
    },
    saveChecklist: async (id: string, data: unknown): Promise<TChecklist> => {
      const response = await http.patch(`/checklists/${id}/save-draft`, { data: JSON.stringify(data) });
      return response.data;
    },
    updateChecklistStatus: async (id: string, completionStatus: EChecklistCompletionStatus): Promise<TChecklist> => {
      const response = await http.patch(`/checklists/${id}/status`, { completionStatus });
      return response.data;
    },
    checklistSeniorReview: async (id: string, reviewerId: string): Promise<TChecklist> => {
      const response = await http.patch(`/checklists/${id}/assign-reviewer`, { id: reviewerId });
      return response.data;
    },
    getReviewDocuments: async (projectId: string): Promise<TDocumentList> => {
      const response = await http.get(`/projects/${projectId}/my-review-documents`);
      return response.data;
    },
    addDocumentChecklist: async (documentId: string, templateId: string, aiEnabled: boolean): Promise<TChecklist> => {
      const response = await http.post(`/documents/${documentId}/checklists/${templateId}`, { aiEnabled });
      return response.data;
    },
    getSelectedChecklists: async (documentId: string): Promise<TChecklist[]> => {
      const response = await http.get(`/documents/${documentId}/checklists`);
      return response.data;
    },
    createIssue: async (documentId: string, data: TCreateIssueCommand): Promise<TIssue> => {
      const response = await http.post(`/documents/${documentId}/issues`, data);
      return response.data;
    },
    getIssuesCategories: async (projectId: string, params: { isDraft: boolean }): Promise<string[]> => {
      const response = await http.get(`/projects/${projectId}/issue-categories`, { params });
      return response.data;
    },
    getIssues: async (projectId: string, params: { category: string; isDraft: boolean }): Promise<TIssue[]> => {
      const response = await http.get(`/projects/${projectId}/issues`, { params });
      return response.data;
    },
    getIssueById: async (id: string): Promise<TIssue> => {
      const response = await http.get(`/issues/${id}`);
      return response.data;
    },
    updateIssue: async (id: string, data: TCreateIssueCommand): Promise<TIssue> => {
      const response = await http.put(`/issues/${id}`, data);
      return response.data;
    },
    deleteIssue: async (id: string): Promise<TIssue> => {
      const response = await http.delete(`/issues/${id}`);
      return response.data;
    },
    updateIssueStatus: async (id: string, status: EIssueStatus): Promise<TIssue> => {
      const response = await http.patch(`/issues/${id}/update-status`, { status });
      return response.data;
    },
    getIssueSummary: async (projectId: string): Promise<TIssueSummaryResponse> => {
      const response = await http.get(`/projects/${projectId}/issue-summary`);
      return response.data;
    },
    notifyModifiedDocument: async (documentId: string, body: { ids: string[] }) => {
      await http.patch(`/documents/${documentId}/notify-modified`, body);
    },
    markDocument: async (
      documentId: string,
      body: { exclude: boolean },
    ): Promise<{ documents: TDocument; duplicateDocuments: TDocument[] }> => {
      const response = await http.patch(`/documents/${documentId}/mark`, body);
      return response.data;
    },
    getNotifications: async (params: { page: number; limit: number }): Promise<TNotificationsPaginatedList> => {
      const response = await http.get(`/notifications`, { params });
      return response.data;
    },
    deleteNotification: async (id: string): Promise<{ id: string }> => {
      const response = await http.delete(`/notifications/${id}`);
      return response.data;
    },
    markAsReadNotification: async (id: string): Promise<{ id: string }> => {
      const response = await http.post(`/notifications/${id}/mark-read`);
      return response.data;
    },
    getUnreadNotoficationCount: async (): Promise<TUnreadNitificationResponse> => {
      const response = await http.get(`/notifications/unread/count`);
      return response.data;
    },
    getIssuesCount: async (documentId: string): Promise<TIssuesCount> => {
      const response = await http.get(`/documents/${documentId}/issues-count`);
      return response.data;
    },
    updateIssueReviewers: async (issueId: string, body: { assignees: string[]; nonAssignees: string[] }) => {
      await http.put(`/issues/${issueId}/reviewers`, body);
    },
    getMyIssues: async (projectId: string, params: { category: string; isDraft: boolean }): Promise<TIssue[]> => {
      const response = await http.get(`/projects/${projectId}/my-issues`, { params });
      return response.data;
    },
    getExportIssues: async (
      projectId: string,
      params?: { category?: string; rating?: string; page: number; limit: number },
    ): Promise<TIssuesPaginatedList> => {
      const response = await http.get(`/projects/${projectId}/issues-export`, { params });
      return response.data;
    },
    getAuditLogs: async (projectId: string, page: number, limit: number): Promise<TAuditLogsPaginatedList> => {
      const params = { page, limit };
      const response = await http.get(`/projects/${projectId}/audit-trails`, { params });
      return response.data;
    },
    getExpandAll: async (documentId: string): Promise<TDocumentList> => {
      const response = await http.get(`/documents/${documentId}/expand-all`);
      return response.data;
    },
    completeDocument: async (documentId: string): Promise<TDocument> => {
      const response = await http.patch(`/documents/${documentId}/complete`);
      return response.data;
    },
    excludeBulkDocuments: async (
      projectId: string,
      data: { documentIds: string[]; exclude: boolean },
    ): Promise<{ documents: TDocument[]; duplicateDocuments: TDocument[] }> => {
      const excludedDocuments = await http.patch(`/projects/${projectId}/bulk-mark`, data);
      return excludedDocuments.data;
    },
    canBulkAssign: async (projectId: string, params: { documentIds: string[] }) => {
      const response = await http.post(`/projects/${projectId}/can-bulk-assign`, params);
      return response.data;
    },
    getUniqueAssignees: async (projectId: string, params: { documentIds: string[] }) => {
      const response = await http.post(`/projects/${projectId}/assignees`, params);
      return response.data;
    },
    bulkAssign: async (projectId: string, data: { documentIds: string[]; reviewerIds: string[] }) => {
      await http.post(`/projects/${projectId}/bulk-assign`, data);
    },
    getTotalDocumentCount: async (projectId: string): Promise<TDocumentsCountSummary> => {
      const response = await http.get(`/projects/${projectId}/document-count`);
      return response.data;
    },
    getProjectChecklists: async (
      projectId: string,
      completionStatus: EChecklistCompletionStatus,
    ): Promise<TChecklist[]> => {
      const response = await http.get(`/projects/${projectId}/checklists`, { params: { completionStatus } });
      return response.data;
    },
    createProjectIssue: async (projectId: string, data: TCreateIssueCommand): Promise<TIssue> => {
      const response = await http.post(`/projects/${projectId}/issues`, data);
      return response.data;
    },
    getDocumentComments: async (documentId: string): Promise<TComment[]> => {
      const response = await http.get(`/documents/${documentId}/comments`);
      return response.data;
    },
    postDocumentComment: async (documentId: string, comment: TDeltaStatic): Promise<TComment> => {
      const response = await http.post(`/documents/${documentId}/comments`, { comment });
      return response.data;
    },
    getIssueComments: async (issueId: string): Promise<TComment[]> => {
      const response = await http.get(`/issues/${issueId}/comments`);
      return response.data;
    },
    postIssueComment: async (issueId: string, comment: TDeltaStatic): Promise<TComment> => {
      const response = await http.post(`/issues/${issueId}/comments`, { comment });
      return response.data;
    },
    getDocumentsToExport: async (projectId: string): Promise<(TDocument & { reviewedByUser: TUser })[]> => {
      const response = await http.get(`/projects/${projectId}/export-documents`);
      return response.data;
    },
    getTeamSummary: async (projectId: string): Promise<TTeamSummary> => {
      const response = await http.get(`/projects/${projectId}/team-summary`);
      return response.data;
    },
    markNotDuplicateDocument: async (
      documentId: string,
    ): Promise<{ documents: TDocument; duplicateDocuments: TDocument[] }> => {
      const response = await http.patch(`/documents/${documentId}/not-duplicate`);
      return response.data;
    },
    getOrganizationFeatureFlags: async (organizationId: string): Promise<TFeatureFlagWithStatus[]> => {
      const response = await http.get(`/organizations/${organizationId}/features`);
      return response.data;
    },
    updateOrganizationFeatureFlags: async (
      organizationId: string,
      featureFlagId: string,
      isEnabled: boolean,
    ): Promise<TOrganisationFeatureFlag> => {
      const response = await http.patch(`/organizations/${organizationId}/features/${featureFlagId}`, { isEnabled });
      return response.data;
    },
    removeChecklist: async (checklistId: string): Promise<TChecklist> => {
      const response = await http.delete(`/checklists/${checklistId}`);
      return response.data;
    },
    getExportTemplate: async (organizationId: string, type: string): Promise<TExportTemplate> => {
      const response = await http.get(`/organization/${organizationId}/export-templates`, { params: { type } });
      return response.data;
    },
    uploadOtherDocument: async (
      projectId: string,
      file: File,
      path: string,
      parentDocId?: string | null,
    ): Promise<TDocument> => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("path", path);
      if (parentDocId) {
        formData.append("parentDocId", parentDocId);
      }
      const response = await http.post(`/projects/${projectId}/hostedDocuments`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    },
    createFolder: async (projectId: string, data: TCreateFolderData): Promise<TDocument> => {
      const response = await http.post(`/projects/${projectId}/hostedDocuments/folder`, data);
      return response.data;
    },
    deleteExternalDocument: async (projectId: string, documentId: string): Promise<TDocument> => {
      const response = await http.delete(`/projects/${projectId}/hostedDocuments/${documentId}`);
      return response.data;
    },
    getAllExportTemplates: async (organizationId: string): Promise<TExportTemplate[]> => {
      const response = await http.get(`/organization/${organizationId}/export-templates`);
      return response.data;
    },
    uploadExportTemplate: async (organizationId, data: TCreateExportTemplateCommand): Promise<TExportTemplate> => {
      const response = await http.post(`/organization/${organizationId}/export-templates`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    },
    deleteExportTemplate: async (organizationId: string, exportTemplateId: string): Promise<{ id: string }> => {
      const response = await http.delete(`/organization/${organizationId}/export-templates/${exportTemplateId}`);
      return response.data;
    },
    getExportTemplateById: async (organizationId: string, exportTemplateId: string): Promise<TExportTemplate> => {
      const response = await http.get(`/organization/${organizationId}/export-templates/${exportTemplateId}`);
      return response.data;
    },
    updateExportTemplate: async (
      organizationId,
      exportTemplateId: string,
      data: TCreateExportTemplateCommand,
    ): Promise<TExportTemplate> => {
      const response = await http.patch(`/organization/${organizationId}/export-templates/${exportTemplateId}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    },
    getFolder: async (projectId: string, folderId: string): Promise<TDocumentList> => {
      const response = await http.get(`/projects/${projectId}/folders/${folderId}`);
      return response.data;
    },
    getHostedDocument: async (projectId: string, documentId: string): Promise<{ documentUrl: string }> => {
      const response = await http.get(`/projects/${projectId}/hostedDocuments/${documentId}`);
      return response.data;
    },
    aiEvaluateChecklist: async (projectId: string, checklistId: string): Promise<TAiDocument> => {
      const response = await http.post(`/projects/${projectId}/checklists/${checklistId}/ai-evaluate`);
      return response.data;
    },
    getAiAnswers: async (projectId: string, checklistId: string): Promise<TAiAnswer[]> => {
      const response = await http.get(`/projects/${projectId}/checklists/${checklistId}/ai-answers`);
      return response.data;
    },
    getAIFields: async (organizationId: string): Promise<TAiOptionDTO[]> => {
      const response = await http.get(`organizations/${organizationId}/ai-fields`);
      return response.data;
    },
    markProjectChecklist: async (
      projectId: string,
      templateId: string,
      aiEnabled: boolean,
    ): Promise<TProjectTemplate> => {
      const response = await http.patch(`/projects/${projectId}/templates/${templateId}`, { aiEnabled });
      return response.data;
    },
    getPlatformAnalytics: async (): Promise<TPlatformAnalytics[]> => {
      const response = await http.get("/projects/analytics");
      return response.data;
    },
  };
}
