import DOMPurify from "dompurify";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { Delta, Sources, Quill } from "react-quill";

import * as styles from "./RichTextArea.css";

import cx from "@web/utils/cx";

export type TextAreaProps = {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  resizable?: boolean;
};

import "react-quill/dist/quill.snow.css";

const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });

const toolbarOptions = ["bold", "italic", "underline", { list: "ordered" }, { list: "bullet" }, "clean"];

function RichTextArea({
  label,
  name,
  placeholder,
  required = false,
  className = "",
  disabled = false,
}: TextAreaProps): React.ReactElement {
  const {
    register,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const [focus, setFocus] = useState(false);

  const error = errors?.[name] as FieldError | undefined;
  const requiredEl = required ? <span className={styles.required}>*</span> : null;

  const addToolbarTitles = () => {
    const TOOLBAR_TITLES: Record<string, string> = {
      "ql-bold": "Bold",
      "ql-italic": "Italic",
      "ql-underline": "Underline",
      "ql-list-ordered": "Ordered List",
      "ql-list-bullet": "Unordered List",
      "ql-clean": "Remove Formatting",
    };
    const toolbarIcons = document.querySelectorAll(".ql-toolbar button");
    toolbarIcons.forEach((button) => {
      const buttonStyle = button.getAttribute("value");
      const className = buttonStyle ? `ql-list-${buttonStyle}` : button.classList[0];
      if (className && TOOLBAR_TITLES[className]) {
        button.setAttribute("title", TOOLBAR_TITLES[className]);
      }
    });
  };

  const handleChange = (value: string, delta: Delta, source: Sources, editor: Quill) => {
    const sanitizedHtml = DOMPurify.sanitize(value);

    clearErrors(name);
    setValue(name, editor.getText() === "\n" ? "" : sanitizedHtml);
  };

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    if (focus) {
      addToolbarTitles();
    }
  }, [focus]);

  const handleFocus = () => {
    if (!disabled) setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };

  const getSanitizedValue = (name: string) => {
    const value = getValues(name);
    return value ? DOMPurify.sanitize(value) : "";
  };

  return (
    <div className={className}>
      <label className={styles.label}>
        {label}
        {requiredEl}
      </label>
      <ReactQuill
        id={name}
        className={cx(styles, {
          textarea: true,
          error: !!error,
          isToolbarVisible: focus,
        })}
        placeholder={placeholder}
        theme="snow"
        value={getSanitizedValue(name)}
        onChange={handleChange}
        readOnly={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        modules={{ toolbar: toolbarOptions }}
      />
      {error && <div className={styles.errors}>{error.message}</div>}
    </div>
  );
}

export default RichTextArea;
